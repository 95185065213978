<template>
  <div
    class="form-property-additional-price"
    @click.capture="handleToggleAction(false)"
  >
    <bg-skeleton
      v-if="loadingFetchData"
      width="40%"
      :height="40"
      data-testid="form-property-additional-price-loader"
    />
    <template v-else>
      <div class="form-property-additional-price__header-section">
        <bg-text size="heading-2">{{ xKosListingData.name }}</bg-text>
      </div>
      <div class="form-property-additional-price__subtitle">
        <bg-text size="heading-4">Biaya dan Layanan Tambahan</bg-text>
        <bg-button
          variant="primary"
          data-testid="form-property-additional-price-add-button"
          @click="handleShowAddEditModal(true)"
          >+ Tambah Biaya & Layanan</bg-button
        >
      </div>

      <table class="ss-table">
        <thead>
          <tr class="form-property-additional-price__table-head">
            <th class="form-property-additional-price__table-col-lg">
              Jenis Biaya
            </th>
            <th class="form-property-additional-price__table-col-md">
              Besar Biaya
            </th>
            <th class="form-property-additional-price__table-col-md">Satuan</th>
            <th class="form-property-additional-price__table-col-sm">Action</th>
          </tr>
        </thead>
        <tbody data-testid="form-property-additional-price-table-body">
          <tr v-for="(unit, i) in fixedFeeData" :key="i">
            <td>{{ unit.label }}</td>
            <td>{{ generatePriceFormat(unit) }}</td>
            <td>{{ generateDuration(unit.duration_unit, unit.duration) }}</td>
            <td class="form-property-additional-price__table-col-sm">
              <bg-icon
                name="more-vertical"
                class="form-property-additional-price__table-action--button"
                data-testid="action-trigger"
                @click.native="handleToggleAction(i, 'type', unit)"
              />
              <bg-card
                v-if="
                  isType &&
                  isShowCardAction.active &&
                  i === isShowCardAction.actionId
                "
                class="form-property-additional-price__table-action"
              >
                <bg-list-item v-for="(action, i) in actions" :key="i">
                  <div
                    class="form-property-additional-price__table-action--row"
                    @click="action.actionClick(unit)"
                  >
                    <bg-text size="input-lg">{{ action.title }}</bg-text>
                  </div>
                </bg-list-item>
              </bg-card>
            </td>
          </tr>
        </tbody>
      </table>

      <table class="ss-table">
        <thead>
          <tr class="form-property-additional-price__table-head">
            <th class="form-property-additional-price__table-col-lg">
              Biaya Lainnya
            </th>
            <th class="form-property-additional-price__table-col-lg">
              Besar Biaya
            </th>
            <th class="form-property-additional-price__table-col-sm">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(unit, i) in paginationData" :key="i">
            <td>{{ unit.name }}</td>
            <td>{{ unit.amount | rupiah }}</td>
            <td class="form-property-additional-price__table-col-sm">
              <bg-icon
                name="more-vertical"
                class="form-property-additional-price__table-action--button"
                @click.native="handleToggleAction(i, 'other', unit)"
              />
              <bg-card
                v-if="
                  isOther &&
                  isShowCardAction.active &&
                  i === isShowCardAction.actionId
                "
                class="form-property-additional-price__table-action"
              >
                <bg-list-item v-for="(action, i) in actions" :key="i">
                  <div
                    class="form-property-additional-price__table-action--row"
                    @click="action.actionClick(unit)"
                  >
                    <bg-text size="input-lg">{{ action.title }}</bg-text>
                  </div>
                </bg-list-item>
              </bg-card>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="form-property-additional-price__footer-section">
        <bg-pagination
          v-if="additionalData.length > pagination.limit"
          v-model="pagination.page"
          :page-total="pagination.total"
          @click="handlePagination"
        />
      </div>

      <additional-add-edit-modal
        :show-modal="isShowAddEditModal"
        :modal-type="addEditModalType"
        :edit-data="modalData"
        :listing-id="listingId"
        :property-id="propertyId"
        data-testid="additional-add-edit-modal"
        @on-close-add-edit-modal="handleShowAddEditModal"
      />

      <additional-delete-modal
        :show-modal="isShowDeleteModal"
        :modal-data="modalData"
        :listing-id="listingId"
        :property-id="propertyId"
        data-testid="additional-delete-modal"
        @on-close-delete-modal="handleShowDeleteModal"
      />
    </template>
  </div>
</template>

<script>
import {
  BgText,
  BgSkeleton,
  BgButton,
  BgIcon,
  BgCard,
  BgListItem,
  BgPagination,
} from 'bangul-vue';
import AdditionalAddEditModal from '@admin/pages/FormPropertyAdditionalPrice/components/AdditionalAddEditModal';
import AdditionalDeleteModal from '@admin/pages/FormPropertyAdditionalPrice/components/AdditionalDeleteModal';
import { mapMutations, mapState, mapActions } from 'vuex';
import { LOADING_STATE } from '@admin_store/modules/propertyDetail';
import { rupiahFormatter } from 'Utils/formatter';

export default {
  name: 'FormPropertyAdditionalPrice',

  components: {
    BgText,
    BgSkeleton,
    BgButton,
    BgIcon,
    BgCard,
    BgPagination,
    BgListItem,
    AdditionalAddEditModal,
    AdditionalDeleteModal,
  },

  created() {
    this.xConsumeKosListing({
      propertyId: this.propertyId,
      listingId: this.listingId,
    });
  },

  filters: {
    rupiah: rupiahFormatter,
  },

  computed: {
    ...mapState('propertyDetail', ['xLoadingStateListing', 'xKosListingData']),
    loadingFetchData() {
      return this.xLoadingStateListing === LOADING_STATE.FETCHING;
    },
    propertyId() {
      return this.$route.params.propertyId;
    },
    listingId() {
      return this.$route.params.listingId;
    },
    actions() {
      return [
        {
          title: 'Ubah',
          actionClick: rowId => {
            this.handleShowAddEditModal(false, rowId);
          },
        },
        {
          title: 'Hapus',
          actionClick: data => {
            this.handleShowDeleteModal(data);
          },
        },
      ];
    },
    additionalFeesArray() {
      const obj = this.xKosListingData?.additional_fees;
      return Object.keys(obj).map(key => obj[key]);
    },
    additionalData() {
      return this.additionalFeesArray.filter(fee => fee.type === 'additional');
    },
    fixedFeeData() {
      return this.additionalFeesArray.filter(fee => fee.type !== 'additional');
    },
    isAdd() {
      return this.addEditModalType === 'add';
    },
  },

  data() {
    return {
      addEditModalType: 'add',
      isShowAddEditModal: false,
      isShowDeleteModal: false,
      modalData: null,
      isOther: false,
      isType: false,
      pagination: {
        page: 1,
        total: 1,
        limit: 10,
      },
      isShowCardAction: {
        active: false,
        actionId: 0,
      },
      additionalFees: null,
      paginationData: [],
    };
  },

  watch: {
    xLoadingStateListing: {
      immediate: true,
      handler(value) {
        if (value === LOADING_STATE.FETCH_DONE) {
          this.additionalFees = this.xKosListingData?.additional_fees;
          this.pagination.total = Math.ceil(
            this.additionalFees.length / this.pagination.limit
          );
          this.handlePagination(this.pagination.page);
          this.handleBreadcrumb();
        }
      },
    },
  },

  methods: {
    ...mapMutations('breadcrumb', ['updateBreadcrumb']),
    ...mapActions('propertyDetail', ['xConsumeKosListing']),

    handleBreadcrumb() {
      this.updateBreadcrumb({
        index: 1,
        item: {
          name: this.xKosListingData.name,
          url: `/property-detail/${this.propertyId}/kos`,
        },
      });
    },

    generatePriceFormat(data) {
      if (data.amount_type === 'nominal') {
        return rupiahFormatter(data.amount);
      } else if (data.amount_type === 'percentage') {
        return `${data.amount}%`;
      }
      return data.amount;
    },

    generateDuration(duration, time) {
      const durationTime = time > 1 ? time : '';
      const durationName = {
        day: `Per ${durationTime} Hari`,
        week: `Per ${durationTime} Minggu`,
        month: `Per ${durationTime} Bulan`,
        year: `Per ${durationTime} Tahun`,
      };

      return duration ? durationName[duration] : '';
    },

    handleToggleAction(actionId, actionCategory) {
      let isCardActive = this.isShowCardAction.active;
      if (typeof actionId === 'boolean') {
        isCardActive = false;
      } else {
        isCardActive = !isCardActive;
      }

      if (actionCategory === 'other') {
        this.isOther = true;
        this.isType = false;
      } else {
        this.isType = true;
        this.isOther = false;
      }

      this.isShowCardAction = {
        active: isCardActive,
        actionId,
      };
    },
    handleShowAddEditModal(isAdd, data) {
      this.isShowAddEditModal = !this.isShowAddEditModal;

      if (this.isShowAddEditModal) {
        if (isAdd) {
          this.addEditModalType = 'add';
          this.modalData = null;
        } else {
          this.addEditModalType = 'edit';
          this.modalData = {
            ...data,
          };
        }
      }
    },

    handleShowDeleteModal(data) {
      this.isShowDeleteModal = !this.isShowDeleteModal;
      this.modalData = data;
    },

    handlePagination(page) {
      const pageArray = this.additionalData.slice(
        (page - 1) * this.pagination.limit,
        page * this.pagination.limit
      );
      this.paginationData = pageArray;
    },
  },
};
</script>

<style lang="scss" scoped src="./FormPropertyAdditionalPrice.scss"></style>
